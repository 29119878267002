import { useState, useEffect, useCallback, useMemo, CSSProperties } from "react";
import { motion, AnimatePresence } from "motion/react";
import useMobile from "@hooks/useMobile";
import { EVENTS, usePubSub } from "@lib/pubsub";
import { ProviderIcon } from "@assets/icons/homepage-icons";
import InputItem from "../items/InputItem";
import InputDropDown from "../items/InputDropDown";
import ProviderLink from "./provider-link";
import PrevArrowSquareIcon from "@assets/icons/general/PrevArrowSquareIcon";
import NextArrowSquareIcon from "@assets/icons/general/NextArrowSquareIcon";
import { Dict } from "@hooks/useLanguage";
interface ProviderListProps {
  heading?: string;
  searchSection?: boolean;
  default_sort_by?: string;
  carousel?: boolean;
  isSlotsPage?: boolean;
}
export default function ProviderList({
  heading,
  searchSection = false,
  default_sort_by = "popular",
  carousel = false,
  isSlotsPage = false
}: ProviderListProps) {
  const [search, setSearch] = useState("");
  const [provider, setProvider] = useState("");
  const [sortBy, setSortBy] = useState(default_sort_by);
  const [resultCount, setResultCount] = useState(6);
  const slotsList = usePubSub(EVENTS.PROVIDERS, []);
  const isMobile = useMobile({
    breakPointThree: 600
  });
  const [startIndex, setStartIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const carouselVariants = {
    hidden: (direction: number) => ({
      x: direction > 0 ? "50px" : "-50px",
      opacity: 0
    }),
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.2
      }
    },
    exit: (direction: number) => ({
      x: direction > 0 ? "-50px" : "50px",
      opacity: 0,
      transition: {
        duration: 0.2
      }
    })
  };
  const handleNext = useCallback(() => {
    const newIndex = startIndex + resultCount;
    if (newIndex < slotsList.length) {
      setDirection(1);
      setStartIndex(newIndex);
    }
  }, [resultCount, startIndex, slotsList.length]);
  const handlePrevious = useCallback(() => {
    const newIndex = startIndex - resultCount;
    if (newIndex >= 0) {
      setDirection(-1);
      setStartIndex(newIndex);
    }
  }, [resultCount, startIndex]);
  useEffect(() => {
    setResultCount(isMobile === "mobile-one" ? 6 : isMobile === "mobile-two" ? 5 : isMobile === "mobile-three" ? 4 : isMobile === "mobile-four" ? 3 : 8);
  }, [isMobile]);
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  const currentItems = useMemo(() => {
    const sorted = [...slotsList].sort((a, b) => a.sort - b.sort);
    return carousel ? sorted.slice(startIndex, startIndex + resultCount) : sorted;
  }, [slotsList, startIndex, resultCount, carousel]);
  return <motion.section initial={{
    opacity: 0
  }} animate={{
    opacity: isLoaded ? 1 : 0
  }} transition={{
    duration: 0.3,
    ease: "easeOut"
  }} className="relative overflow-x-hidden pt-1 flex flex-col gap-[14px]" data-sentry-element="unknown" data-sentry-component="ProviderList" data-sentry-source-file="provider-list.tsx">
      {heading && <div className="flex items-center gap-2.5">
          <ProviderIcon className="w-[22px] h-[22px] text-white" />
          <Dict name={heading} as="p" section="common" className="text-lg font-medium text-white" />
        </div>}

      <div className="flex absolute right-0 top-0">
        <button className={`flex items-center justify-center w-[49px] h-[32px] rounded-[6px] border border-solid border-[rgba(124,131,177,0.2)] rounded-r-none cursor-pointer border-r-[0.5px] bg-[#292E52] transition-all duration-100 ${startIndex === 0 ? "opacity-50 cursor-default" : "hover:border-[rgba(124,131,177,0.4)] hover:from-[rgba(124,131,177,0.4)] hover:to-[rgba(94,103,158,0.4)]"}`} onClick={startIndex === 0 ? null : handlePrevious}>
          <PrevArrowSquareIcon className={`${startIndex === 0 ? "opacity-50" : ""}`} data-sentry-element="PrevArrowSquareIcon" data-sentry-source-file="provider-list.tsx" />
        </button>

        <button className={`flex items-center justify-center w-[49px] h-[32px] rounded-[6px] border border-solid border-[rgba(124,131,177,0.2)] rounded-l-none cursor-pointer border-l-[0.5px] bg-[#292E52] transition-all duration-100 ${startIndex + resultCount >= slotsList.length ? "opacity-50 cursor-default" : "hover:border-[rgba(124,131,177,0.4)] hover:from-[rgba(124,131,177,0.4)] hover:to-[rgba(94,103,158,0.4)]"}`} onClick={startIndex + resultCount >= slotsList.length ? null : handleNext}>
          <NextArrowSquareIcon className={`${startIndex + resultCount >= slotsList.length ? "opacity-50" : ""}`} data-sentry-element="NextArrowSquareIcon" data-sentry-source-file="provider-list.tsx" />
        </button>
      </div>

      {searchSection && <div className="flex flex-col gap-3 p-6 rounded-lg bg-gradient-to-b from-[#171c36] to-[#171c36]/80 border border-white/5">
          <InputItem name="search" value={search} onChange={setSearch} placeholder="Search" icon={<svg className="w-5 h-5 fill-current">
                {/* Search icon path */}
              </svg>} />
          <div className="flex gap-3">
            <InputDropDown value={provider} options={[{
          value: "Bgaming",
          label: "Bgaming"
        }]} onSelect={setProvider} placeholder="Provider" />
            <InputDropDown value={sortBy} options={[{
          value: "popular",
          label: "Popular"
        }, {
          value: "amount-won",
          label: "Amount Won"
        }, {
          value: "a-z",
          label: "A-Z"
        }, {
          value: "z-a",
          label: "Z-A"
        }]} onSelect={setSortBy} placeholder="Sort By" />
          </div>
        </div>}

      <AnimatePresence initial={false} mode="wait" custom={direction} data-sentry-element="AnimatePresence" data-sentry-source-file="provider-list.tsx">
        <motion.div key={carousel ? startIndex : "static"} custom={direction} variants={carouselVariants} initial={carousel ? "hidden" : false} animate="visible" exit="exit" className="grid grid-flow-col gap-2.5 auto-cols-[calc(100%_/_var(--cols)_-_10px_*_(var(--cols)-1)/var(--cols))] select-none" style={{
        "--cols": resultCount
      } as CSSProperties} drag={carousel ? "x" : false} dragConstraints={{
        right: 0,
        left: 0
      }} dragElastic={0.2} onDragStart={() => setIsDragging(true)} onDragEnd={(e, {
        offset,
        velocity
      }) => {
        setIsDragging(false);
        const swipeThreshold = 50;
        const swipePower = Math.abs(offset.x) * velocity.x;
        if (swipePower < -swipeThreshold) {
          handleNext();
        } else if (swipePower > swipeThreshold) {
          handlePrevious();
        }
      }} data-sentry-element="unknown" data-sentry-source-file="provider-list.tsx">
          {currentItems.map((item, index) => <div key={`${item.id}-${startIndex + index}`} className={`${isDragging ? "pointer-events-none" : ""}`}>
              <ProviderLink item={item} isSlotsPage={isSlotsPage} />
            </div>)}
        </motion.div>
      </AnimatePresence>
    </motion.section>;
}