import React from "react";
import st from "../styles/items/Input.module.scss";
import { classnames } from "@lib/tools/helpers";
export default function InputDropDown(props) {
  const {
    label,
    errors,
    name = false,
    readOnly = false,
    placeholder,
    classType,
    style,
    hasPaddingBottom = true,
    icon = false,
    labelText = false
  } = props;
  const activeSelect = {
    disabled: readOnly
  };
  return <div style={style} {...classnames(st, "input-drop-down", icon && "custom-width", classType, icon && "row-label", label && "icon-padding", !hasPaddingBottom && "no-bottom-padding", "inputWrapper", errors?.[name]?.length && "error")} data-sentry-component="InputDropDown" data-sentry-source-file="InputDropDown.jsx">
      {label ? <div className={st["label-heading"]}>
          {/* Errors will appear here */}
          <label htmlFor={name}>
            {label}
            {/* Will display errors */}
            {errors ? errors[name]?.length ? errors[name].map((item, i) => {
          return <span className={st["error-item"]} key={i}>
                        {item}
                      </span>;
        }) : null : null}
          </label>
        </div> : null}

      <div className={st["container-icon"]}>
        {icon && <span>
            {icon} <label htmlFor={name}>{labelText}:</label>
          </span>}
        <select {...activeSelect} defaultValue={props.selectedOption} defaultChecked={props.selectedOption} value={props.selectedOption} onChange={props.onSelectOption} id={name} name={name}>
          {placeholder && <option value="" disabled hidden>
              {placeholder}
            </option>}
          {props.options.map(option => <option key={option.value} value={option.value}>
              {option.label}
            </option>)}
        </select>
      </div>
    </div>;
}