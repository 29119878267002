import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
interface ProviderLinkProps {
  item: {
    code: string;
    name: string;
    logo?: string;
    icon?: string;
  };
  providerPage?: boolean;
  isSlotsPage?: boolean;
}
export default function ProviderLink({
  item,
  providerPage = false,
  isSlotsPage = false
}: ProviderLinkProps) {
  const pathname = usePathname();
  const href = isSlotsPage ? pathname : "/casino/slots";
  const src = item.logo || "/Images/fallback-image.png";
  return <Link shallow prefetch={false} href={providerPage ? {
    pathname: `/casino/providers/${item.code}`
  } : {
    pathname: href.replace(/\/+/g, "/"),
    query: {
      provider: item.name
    }
  }} className="block bg-[#282D49] rounded-md p-2.5 border border-transparent hover:border-white/10 border-solid hover:-mt-[3px] duration-150" style={{
    transitionTimingFunction: "linear"
  }} data-sentry-element="Link" data-sentry-component="ProviderLink" data-sentry-source-file="provider-link.tsx">
      <div className="relative w-full h-[45px] [@media(min-width:640px)]:w-[90px] [@media(min-width:640px)]:h-[45px] mx-auto">
        <Image src={src} alt={item.name} fill quality={100} priority={true} sizes="(max-width: 640px) 100vw, 90px" className="object-contain" loading="eager" data-sentry-element="Image" data-sentry-source-file="provider-link.tsx" />
      </div>
    </Link>;
}